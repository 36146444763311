import React from 'react'
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({component}) {
    const userToken = localStorage.getItem("token");
    if(userToken && userToken.length){
        return component;
    }
  return <Navigate to={'/login'}/>
}
