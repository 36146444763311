import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import defaultProfilePic from "../../assets/noImage.png"; // Import the default image
import QRCode from "react-qr-code";

const UserView = () => {
  const [user, setUser] = useState({});
  const { userId } = useParams();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/view`,
          {
            params: { id: userId },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, [userId]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">User Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    {user && user?.user && (
                      <>
                        <div className="user-info">
                          <strong>Name:</strong> {user?.user?.name}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>UserName:</strong> {user?.user?.userName}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Email:</strong> {user?.user?.email}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Contact Number:</strong> +
                          {user?.user?.countryCode +
                            "-" +
                            user?.user?.contactNumber}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Balance:</strong> ${user?.user?.balance}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Profile Pic:</strong>
                          <br />
                          <div className="profile-pic-wrapper">
                            <img
                              src={user?.user?.imageUrl || defaultProfilePic}
                              alt="Profile"
                              className="profile-pic"
                            />
                          </div>
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>QR:</strong>
                          <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                            <QRCode
                              size={256}
                              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                              value={user?.user?.countryCode + user?.user?.contactNumber }
                              viewBox={`0 0 256 256`}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
