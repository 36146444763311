import React from 'react'
import Header from '../../components/Header'
import SideNav from '../../components/SideNav'
import { Outlet } from 'react-router-dom'

export default function UnAuthLayout() {
  return (
    <div className="hold-transition login-page">
    {/* // <div className="hold-transition"> */}
        <Outlet/>
    </div>
  )
}
