export const trimContent = (text, length) => {
    if (!text || typeof text !== "string") {
      return "";
    }
  
    if (text.length <= length) {
      return text;
    } else {
      return text.slice(0, length) + "...";
    }
  };



  export const remove_p_br_span_Tags = (text) => {
    // Remove <p>, </p>, <br>, <li>, and <span> tags
    let result = text
      .replace(/<\/?p>/gi, "")
      .replace(/<br\s*\/?>/gi, "")
      .replace(/<li\s*\/?>/gi, "")
      .replace(/<\/?span[^>]*>/gi, "");
  
    return result;
  };