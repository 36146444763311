import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const OfferView = () => {
  const [offer, setOffer] = useState({});
  const { offerId } = useParams();

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/offers/view`,
          {
            headers: {
              "api-key": "12345-abcde-67890-fghij",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: { id: offerId },
          }
        );
        console.log("responseeeee", response);
        setOffer(response?.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchOfferDetails();
  }, [offerId]);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Offer Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-body view-page">
                    {offer && offer?.data && (
                      <>
                        <div className="user-info">
                          <strong>Crypto Currency:</strong>{" "}
                          {offer?.data?.cryptoCurrency}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Fiat Currency:</strong>{" "}
                          {offer?.data?.fiatCurrency}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Trade Price Type:</strong>{" "}
                          {offer?.data?.tradePriceType}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Transaction Type:</strong>{" "}
                          {offer?.data?.transactionType}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Payment Method:</strong>{" "}
                          {offer?.data?.paymentMethod}
                        </div>
                        <br />
                        <div className="user-info">
                          <strong>Trade Price Limit:</strong>{" "}
                          {offer?.data?.tradePriceLimit ? (
                            <>
                              Minimum: {offer?.data?.tradePriceLimit?.minimum},
                              Maximum: {offer?.data?.tradePriceLimit?.maximum}
                            </>
                          ) : (
                            "No trade price limit available"
                          )}
                        </div>
                        <br />

                        <div className="user-info">
                          <strong>Offer Margin:</strong>{" "}
                          {offer?.data?.offerMarginPercent}%
                        </div>
                        <br />

                        <div className="user-info">
                          <strong>Offer Terms:</strong> {offer?.data?.offerTerms}
                        </div>
                        <br />

                        <div className="user-info">
                          <strong>Trade Instructions:</strong>{" "}
                          {offer?.data?.tradeInstructions}
                        </div>
                        <br />

                        <div className="user-info">
                          <strong>Created By:</strong>{" "}
                          {offer?.userInfo?.user?.name}
                        </div>
                        <br />
                        {/* <div className="user-info">{" "}
                          <strong>is Active:</strong> {offer?.data?.isActive}
                        </div>
                        <br /> */}

                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferView;
