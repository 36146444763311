import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import Swal from "sweetalert2";

const SideNav = () => {
  const [adminName, setAdminName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");

  const handleLogout = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        navigate("/login");
        toast.success("You have been logged out!");
      }
    });
  };
  
  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === "/";
    } else {
      return location.pathname.startsWith(path);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("respp", response);
        setAdminName(response?.data?.data?.name);
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* <Link to="/admin-view" className="brand-link">
          <img
            src="dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">{adminName}</span>
        </Link> */}

        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              {/* <img
                src="dist/img/user2-160x160.jpg"
                className="img-circle elevation-2"
                alt="User Image"
              /> */}
            </div>
            <Link to="/admin-view" className="info">
              <div className="d-block" style={{ color: "white" }}>
                {adminName}
              </div>
            </Link>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {token && (
                <>
                  <li className="nav-item menu-open">
                    <Link
                      to="/dashboard"
                      className={`nav-link ${
                        isActive("/dashboard") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa fa-dashboard"></i>
                      <p>Dashboard</p>
                    </Link>
                  </li>
                  <br />

                  <li className="nav-item menu-open">
                    <Link
                      to="/users"
                      className={`nav-link ${
                        isActive("/users") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-user"></i>
                      <p>User Management</p>
                    </Link>
                  </li>
                  <br />

                  <li className="nav-item menu-open">
                    <Link
                      to="/transaction-management"
                      className={`nav-link ${
                        isActive("/transaction-management") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>Transaction Monitoring</p>
                    </Link>
                  </li>
                  <br />
                  <li className="nav-item menu-open">
                    <Link
                      to="/offer"
                      className={`nav-link ${
                        isActive("/offer") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>Offer Management</p>
                    </Link>
                  </li>
                  <br />
                  {/* <li className="nav-item menu-open">
                    <Link
                      to="/wallet-management"
                      className={`nav-link ${
                        isActive("/wallet-management") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-key"></i>
                      <p>Wallet Management</p>
                    </Link>
                  </li>
                  <br /> */}
                  <li className="nav-item menu-open">
                    <Link
                      to="/contact-us"
                      className={`nav-link ${
                        isActive("/contact-us") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-folder"></i>
                      <p>Contact Us</p>
                    </Link>
                  </li>
                  <br />
                  <li className="nav-item menu-open">
                    <Link
                      to="/faq"
                      className={`nav-link ${isActive("/faq") ? "active" : ""}`}
                    >
                      <i className="nav-icon fas fa-comments"></i>
                      <p>Faq Management</p>
                    </Link>
                  </li>
                  <br />
                  <li className="nav-item menu-open">
                    <Link
                      to="/content-management"
                      className={`nav-link ${
                        isActive("/content-management") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-folder"></i>
                      <p>Content Management</p>
                    </Link>
                  </li>

                  <br />

                  <li className="nav-item menu-open">
                    <Link
                      to="/email"
                      className={`nav-link ${
                        isActive("/email") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-envelope"></i>
                      <p>Email Template</p>
                    </Link>
                  </li>
                  <br />

                  <li className="nav-item menu-open">
                    <Link
                      to="/notification"
                      className={`nav-link ${
                        isActive("/notification") ? "active" : ""
                      }`}
                    >
                      <i className="nav-icon fas fa-bell"></i>
                      <p>Notification</p>
                    </Link>
                  </li>
                  <br />

                  <li className="nav-item menu-open">
                    <a
                      href="/logout"
                      className="nav-link"
                      onClick={handleLogout}
                    >
                      <i className="" />
                      <i className="nav-icon fas fa-sign-out-alt"></i>
                      <p>Logout</p>
                    </a>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default SideNav;
