import React, { useEffect, useState } from "react";
import axios from "axios";

const TermsConditions = () => {
  const [data, setData] = useState("");

  const fetchPages = async () => {
    try {
      const response = await axios
        .get(`${process.env.REACT_APP_API_URL}/pages/slug`, {
          params: { slug: "terms-conditions" },
        })
        .then((res) => {
          console.log("res", res);
          setData(res?.data?.page);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width" />
      <title>Terms &amp; Conditions</title>
      <div className="container">
       <div className="static-page-des"> 
        <h1>{data?.name}</h1>
        <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
       </div>
      </div>
    </>
  );
};

export default TermsConditions;
