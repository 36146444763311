import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import AdminLayout from "./view/shared/AdminLayout";
import UnAuthLayout from "./view/shared/UnAuthLayout";
import PrivateRoute from "./view/shared/PrivateRoute";
import Dashboard from "./view/Admin/Dashboard";
import ViewAdmin from "./view/Admin/View";
import ChangePassword from "./view/Admin/ChangePassword";
import Login from "./view/Admin/Login";
import ResetPassword from "./view/Admin/ResetPassword";
import ForgotPassword from "./view/Admin/ForgotPassword";
import UnauthenticatedRoute from "./view/shared/UnauthenticatedRoute";
import PaymentManagement from "./view/PaymentManagement";
import WalletManagement from "./view/WalletManagement";
import TransactionManagement from "./view/TransactionManagement";
import User from "./view/UserManagement/User";
import UserView from "./view/UserManagement/UserView";
import UserEdit from "./view/UserManagement/UserEdit";
import AddFaq from "./view/Faq/AddFaq";
import Faq from "./view/Faq/Faq";
import UpdateFaq from "./view/Faq/UpdateFaq";
import ContactUs from "./view/ContactUs/ContactUs";
import ContentManagement from "./view/ContentManagement/ContentManagement";
import UpdateContent from "./view/ContentManagement/UpdateContent";
import TermsConditions from "./webpages/Terms-Conditions";
import PrivacyPolicy from "./webpages/Privacy-Policy";
import AboutUs from "./webpages/About-Us";
import Help from "./webpages/Help";
import EmailTemplates from "./view/EmailManagement/EmailTemplates";
import UpdateEmailTemplate from "./view/EmailManagement/UpdateEmailTemplate";
import UpdateContactUs from "./view/ContactUs/UpdateContactUs";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      //admin route
      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        path: "/dashboard",
        element: <PrivateRoute component={<Dashboard />} />,
      },
      {
        path: "/admin-view",
        element: <PrivateRoute component={<ViewAdmin />} />,
      },
      {
        path: "/admin-change-password",
        element: <PrivateRoute component={<ChangePassword />} />,
      },

      //users route
      {
        path: "/users",
        element: <PrivateRoute component={<User />} />,
      },
      {
        path: "/users-view/:userId",
        element: <PrivateRoute component={<UserView />} />,
      },
      {
        path: "/users-edit/:userId",
        element: <PrivateRoute component={<UserEdit />} />,
      },

      // /faq route
      {
        path: "/faq",
        element: <PrivateRoute component={<Faq />} />,
      },
      {
        path: "/faq-add",
        element: <PrivateRoute component={<AddFaq />} />,
      },
      {
        path: "/faq-update/:faqId",
        element: <PrivateRoute component={<UpdateFaq />} />,
      },

      // Contact us route
      {
        path: "/contact-us",
        element: <PrivateRoute component={<ContactUs />} />,
      },
      {
        path: "/contact-us-update/:userId",
        element: <PrivateRoute component={<UpdateContactUs />} />,
      },

      //cms route
      {
        path: "/content-management",
        element: <PrivateRoute component={<ContentManagement />} />,
      },
      {
        path: "/content-management-update/:pageId",
        element: <PrivateRoute component={<UpdateContent />} />,
      },

       //email template route
       {
        path: "/email",
        element: <PrivateRoute component={<EmailTemplates />} />,
      },
      {
        path: "/email-update/:emailId",
        element: <PrivateRoute component={<UpdateEmailTemplate />} />,
      },

      {
        path: "/payment-management",
        element: <PrivateRoute component={<PaymentManagement />} />,
      },

      {
        path: "/wallet-management",
        element: <PrivateRoute component={<WalletManagement />} />,
      },

      {
        path: "/transaction-management",
        element: <PrivateRoute component={<TransactionManagement />} />,
      },

      {
        path: "/logout",
        element: <PrivateRoute component={<Login />} />,
      },
    ],
  },
  {
    path: "/",
    element: <UnAuthLayout />,
    children: [
      {
        path: "/login",
        element: <UnauthenticatedRoute component={Login} />,
      },
      {
        path: "/reset-password",
        element: <UnauthenticatedRoute component={ResetPassword} />,
      },
      {
        path: "/forget-password",
        element: <UnauthenticatedRoute component={ForgotPassword} />,
      },
    ],
  },

  //cms web pages

  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/help",
    element: <Help />,
  },
]);

export default routes;
