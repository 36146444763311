import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { remove_p_br_span_Tags } from "../../services/helper";

const UpdateEmailTemplate = () => {
  const [email, setEmail] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    subject: "",
    content: "",
  });
  const [errors, setErrors] = useState({});
  const { emailId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmailDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/emails/view`,
          {
            params: { id: emailId },
          }
        );
        setEmail(response?.data);
        setFormData({
          title: response?.data?.email?.title,
          subject: response?.data?.email?.subject,
          content: response?.data?.email?.content,
        });
      } catch (error) {
        console.error("Error fetching email details:", error);
      }
    };
    fetchEmailDetails();
  }, [emailId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.content.trim()) {
      newErrors.content = "Content is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/emails/update`, {
        id: emailId,
        ...formData,
      });
      toast.success("Email details updated successfully!");
      navigate("/email");
    } catch (error) {
      console.error("Error updating email details:", error);
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Email Template</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleFormSubmit}>
                    <div className="card-body">
                      {email && email?.email && (
                        <>
                          <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                              type="text"
                              name="title"
                              className={`form-control ${
                                errors.title ? "is-invalid" : ""
                              }`}
                              placeholder="Enter title"
                              value={formData?.title}
                              onChange={handleInputChange}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {errors.title}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="subject">Subject</label>
                            <input
                              type="text"
                              name="subject"
                              className={`form-control ${
                                errors.subject ? "is-invalid" : ""
                              }`}
                              placeholder="Enter subject"
                              value={formData?.subject}
                              onChange={handleInputChange}
                            />
                            {errors.subject && (
                              <div className="invalid-feedback">
                                {errors.subject}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label htmlFor="answer">Content</label>
                            <textarea
                              type="text"
                              name="content"
                              className={`form-control ${
                                errors.content ? "is-invalid" : ""
                              }`}
                              placeholder="Enter content"
                              value={remove_p_br_span_Tags(formData?.content)}
                              onChange={handleInputChange}
                              style={{ height: "100px" }}
                            />
                            {errors.content && (
                              <div className="invalid-feedback">
                                {errors.content}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEmailTemplate;
