import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ContentManagement = () => {
  const [pages, setPages] = useState([]);

  const fetchPages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pages/list`
      );
      console.log("response", response);
      setPages(response?.data?.data);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Content Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Page Name</th>
                          <th>Slug</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pages.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.name}</td>
                            <td>{data?.slug}</td>
                            <td>
                              <Link
                                to={`/content-management-update/${data?._id}`}
                                className="btn btn-link merch-edit"
                              >
                                <i className="fas fa-edit"></i>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
