import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddFaq = () => {
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    Object.keys(formData).forEach((key) => {
      const value = formData[key];
      if (!value.trim()) {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
      } else if (value.length > 1000) {
        newErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } not more then 1000 words`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/faq/add`, formData);
      toast.success("Faq added successfully!");
      navigate("/faq");
    } catch (error) {
      console.error("Error adding faq:", error);
      toast.error("Failed to add faq. Please try again.");
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Faq Management</h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <form id="quickForm" onSubmit={handleSubmit}>
                    <div className="card-body">
                      <>
                        <div className="form-group">
                          <label htmlFor="question">Question</label>
                          <input
                            type="text"
                            name="question"
                            className={`form-control ${
                              errors.question ? "is-invalid" : ""
                            }`}
                            value={formData.question}
                            onChange={handleChange}
                            placeholder="Enter question"
                          />
                          {errors.question && (
                            <div className="invalid-feedback">
                              {errors.question}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="answer">Answer</label>
                          <textarea
                            type="text"
                            name="answer"
                            className={`form-control ${
                              errors.answer ? "is-invalid" : ""
                            }`}
                            value={formData.answer}
                            onChange={handleChange}
                            placeholder="Enter answer"
                          />
                          {errors.answer && (
                            <div className="invalid-feedback">
                              {errors.answer}
                            </div>
                          )}
                        </div>
                      </>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFaq;
