import React from "react";

const WalletManagement = () => {
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Wallet Management</h1>
              </div>
              {/* /.col */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletManagement;
