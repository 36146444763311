import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [offerCount, setOfferCount] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);

  useEffect(() => {
    const totalUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/count`
        );

        setUserCount(response?.data?.count);

        // Fetch Offer Count
        const offerResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/offers/count`,
          {
            headers: {
              "api-key": "12345-abcde-67890-fghij",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setOfferCount(offerResponse?.data?.count);

        // Fetch Transaction Count
        const transactionResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/transactions/count`,
          {
            headers: {
              "api-key": "12345-abcde-67890-fghij",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setTransactionCount(transactionResponse?.data?.count);
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };
    totalUser();
  }, []);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{userCount}</h3>
                    <p>New Users</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/users" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>{offerCount}</h3>
                    <p>Offers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/offer" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{transactionCount}</h3>
                    <p>Transactions</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/transaction-management" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
